  .company-profile-container {
    max-width: 1200px;
    margin: 0 auto;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  .company-details {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .detail-item {
    width: 48%; /* Allows two items per row on larger screens */
    margin-bottom: 15px;
    text-align: center;
  }
  
  .detail-item h3 {
    font-weight: bold;
    color: #555;
    margin-bottom: 5px;
  }
  
  .detail-item p {
    color: #333;
    line-height: 1.5;
    text-align: center;
  }
  
  .detail-item a {
    color: #007BFF;
    text-decoration: none;
  }
  
  .detail-item a:hover {
    text-decoration: underline;
  }

  .location-container {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
   
  }



  .why-choose-container {
    padding: 10px;
    margin: 0 auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .why-choose-container , .overview {
    background-color: #f8f9fa;
  }
  
  .content-title {
    color: #333;
    margin-bottom: 20px;
  }
  
  .text-content {
    line-height: 1.6;
    color: #555;
    margin-bottom: 15px;
  }

  .location-banner {
    background-image: url('../../../public/images/head-office.jpg');
    background-position: center;
    background-size: cover; /* Ensures the entire image is visible */
    background-repeat: no-repeat; /* Prevents repeating of the image */
    width: 100%; /* Full width */
    height: 400px; /* Controls the height */
    margin: 10px 0;
  }