/* tireSelection.css */

.tire-selection-container {
  padding: 5px;
  border-radius: 8px;
  box-shadow: 0 2px 10px #1da1f2;
  color: #fff; /* White text for contrast */
  margin: 37px 0;
  width: 60%;
  box-sizing: border-box;
  position: relative;
  max-width: 100%;
}

.orders-wrapper {
  height: 300px; /* Fixed height for the orders table */
  overflow-y: auto; /* Allow vertical scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  overflow-x: visible;
}

.image-container .text {
  font-size: 30px;
}

.tire-selection-container h3 {
  margin-bottom: 20px;
  text-align: center;
}


.orders-table {
  width: 100%;
  border-collapse: collapse;
}

.orders-table td, .orders-table th {
  font-size: 14px;
}

.orders {
  background-color: #34495e;
}

.orders-table th, .orders-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
  white-space: nowrap; /* Prevent text wrapping */
  
}

.print-table td {
  color: #333;
}
.orders-table th {
  background-color: #1da1f2; /* Light blue header */
  color: white;
}

.orders-table tr:nth-child(even) {
  background-color: rgba(255, 255, 255, 0.1); /* Slightly transparent white */
}

.orders-table tr:hover {
  color: white;
}

.action-button, .edit-button, .delete-button, .prev-next-button, .continue-selection-btn {
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.action-button {
  background-color: #1da1f2; /* Light blue */
  color: white;
}

.action-button:hover {
  background-color: #1e3a8a; /* Dark blue on hover */
}

.prev-next-button {
  background-color: #e67e22; /* Orange */
  color: white;
}

.prev-next-button:hover {
  background-color: #1da1f2; /* Light blue on hover */
}

.pagination-controls {
  margin: 10px;
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
}

.group-dropdown {
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.confirmation-message {
  margin: 20px 0;
  color: #28a745; /* Green for confirmation messages */
}

.total-order {
  font-weight: bold;
}

.continue-selection-btn , .send-order-btn{
  background-color: #1da1f2; /* Light blue */
  color: white;
  padding: 10px 20px;
  border: none;
  outline: none;
}

.continue-selection-btn:hover , .send-order-btn:hover {
  background-color: #1e3a8a; /* Dark blue on hover */
}

.table-btns {
  display: flex;
  gap: 10px;
  align-items: baseline;
}

.edit-button {
  background-color: #1da1f2; /* Light blue for edit */
  color: white;
}

.edit-button:hover {
  background-color: #1e3a8a; /* Dark blue on hover */
}

.delete-button {
  background-color: #e62222 !important; /* Orange for delete */
  color: white;
}

.delete-button:hover {
  background-color: #df3333; /* Darker orange on hover */
}

.show-orders-btn:disabled {
  cursor: not-allowed;
}

.make-list-controls {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 10px;
}

.print-button {
  background-color: #29a3f2;
  color: white;
  border: none;
  padding: 10px 20px;

  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

.print-button:hover {
  background-color: #1a8cc8;
}

.alert-modal-btns {
      display: flex;
      justify-content: center;
      gap: 10px;
}

.print-table {
  -webkit-print-color-adjust: exact !important;
  background-size: cover !important;
  background-image: url('../../../public/images/printwatermark.png');
}

.action-btns {
  display: flex;
  gap: 10px;
}

.action-btns a {
  text-decoration: none;
}