.adminAddUserContainer {
    margin-bottom: 2rem;
    padding: 2rem;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    max-width: 500px;
    margin: auto;
    position: relative;
  }
  
  .adminAddUserTitle {
    color: #333;
    margin-bottom: 2.5rem !important;
    text-align: center;
  }
  
  .adminAddUserMessage {
    margin-bottom: 1rem;
    color: #007bff;
    font-size: inherit;
  }

  
  .adminAddUserForm {
    display: flex;
    flex-direction: column;
  }
  
  .adminAddUserLabel {
    display: block;
    margin-bottom: 0.5rem;
    color: #555;
    font-weight: 500;
  }
  
  .adminAddUserInput {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1rem;
    box-sizing: border-box;
  }
  
  .adminAddUserInput:focus {
    outline: none;
    border-color: #007bff;
  }
  
  .adminAddUserButton {
    background-color: var(--primary-color);
    color: white;
    padding: 0.75rem;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .adminAddUserButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .adminAddUserButton:hover:not(:disabled) {
    background-color: #0056b3;
  }
  
