

/* General Styles */
.main-header {
  background-color: #1e3a8a;
  padding: 10px 30px;
  color: white;
}

.header-item a {
  color: inherit;
  text-decoration: none;
}

.main-header .logo {
  position: relative;
  top: 10px;
}
.used-cars , .used-tires {
  cursor: pointer;
  position: relative;
}

/* General styling for the links container */
.used-cars, .used-tires {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.used-cars i, .used-tires i {
  margin-right: 8px;
  color: #fff; /* Adjust the color for the icons */
}

.used-cars a, .used-tires a {
  color: #fff; /* Adjust link color */
  font-weight: bold;
  text-decoration: none;
}

.used-cars a:hover, .used-tires a:hover {
  text-decoration: underline;
}

.dropdown {
  position: relative;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-top img {
  width: 130px;
  margin: 0 46px;
}

.app-name {
  font-weight: bold;
  color: white;

  margin: 0 55px;
}

.header-search {
  display: flex;
  align-items: center;
  position: relative;
  flex-grow: 1;
  margin: 0 45px;
}

.header-search input {
  width: 100%;
  padding: 10px 40px 10px 20px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  border-radius: 16px;
}

.header-search input:focus {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.search-icon {
  position: absolute;
  right: 15px;
  color: #888;
  cursor: pointer;
}

.header-icons {
  display: flex;
  gap: 20px;
  align-items: center;
}

.header-item {
  position: relative;
  cursor: pointer;
}

.header-item i {
  margin-right: 5px;
}

/* Left and right links styling */
.header-bottom {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.left-links, .right-links {
  display: flex;
  gap: 20px;
}

.dropdown-content::after {
  content: "";
  position: absolute;
  top: 0; /* Position it above the dropdown */
  right: 0; /* Adjust right positioning as needed */
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #2980b9  transparent; /* Arrow pointing down */
}

/* Enhanced dropdown styles for used cars, used tires, and right links */
.dropdown-content {
  display: none;
  position: absolute;
  top: 25px; /* Slightly adjusted for better placement */
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  padding: 12px;
  z-index: 100;
  color: #333; /* Darker text for better readability */
  text-align: center; /* Aligned left for better UX */
  width: 180px; /* Slightly wider for more content */
  transition: all 0.3s ease; /* Smooth transition for dropdown appearance */
}


.dropdown-content.show {
  display: block;
  opacity: 1;
}

.dropdown-content a {
  padding: 8px 10px; /* Adjusted padding for more balanced spacing */
  display: block;
  text-decoration: none;
  color: #333;
  font-weight: 500;
  border-bottom: 1px solid #f0f0f0;
  transition: background-color 0.2s ease, color 0.2s ease;
}

.dropdown-content a:last-child {
  border-bottom: none; /* Removes border from the last link */
}

.dropdown-content a:hover {
  background-color: #e0f3ff; /* Softer highlight color */
  color: #1a73e8; /* Google blue for emphasis */
  border-radius: 6px;
}


.header-container .banner-image {
  height: 100px;

}

.local-services-banner {
  display: block;
  margin: 0 auto;
  width: 100%;
}

.gifs-container {
  display: flex;
  justify-content: center;
}

.profile a, .profile button {
  margin: 5px 0;
}

.profile button {
  margin: 15px 0;
}

.right-links a {
  color: inherit;
  text-decoration: none;
}

.nav-item.dropdown {
  cursor: pointer;
}