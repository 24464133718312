
/* Improved Banner Section */
.banner {
  width: 100%;
  overflow: hidden;
  text-align: center;
}

.banner-image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  object-position: center;
}

.main-title {
  font-weight: bold;
  color: #333;
  margin: 20px 0;
  text-align: center;
}

.sub-title {
  font-weight: bold;
  color: #333;
  text-align: center;
  margin: 20px 0;
}

.intro-content {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin: 40px 0;
  gap: 20px;
}

.intro-content p {
  color: #333;
  line-height: 1.6;
  padding: 50px 20px;
  border: 1px solid #ccc;
  margin: 1px 0;
}

.intro-content img {
  height: auto;

}

.nav {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.nav a {
  justify-content: center;
  margin: 0 15px;
  text-decoration: none;
  color: #333;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nav a:hover {
  color: #003366;
  text-decoration: underline;
}

.nav a i {
  margin-right: 5px;
}

.section-title {
  position: relative;
  padding: 5px 40px; /* Adjust horizontal padding */
  text-align: center;
  font-weight: bold;
  z-index: 1; /* Ensure the text is above the pseudo-elements */
  margin: 0;
}

.services {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
/*
.section-title::before,
.section-title::after {
  content: "";
  position: absolute;
  top: 50%;
  height: 45px; 
  background-color: #1e398a;
  transform: translateY(-50%);
  z-index: -1; 
}

.section-title::before {
  left: 0;
  width: calc(100% - 40px); 
}

.section-title::after {
  right: -45%;
  width: calc(100% - 40px); 

}

*/

.namibia-agent-container .services, .congo-agent-container .services {
  padding: 40px 30px;
  margin: 0;
}

.services h3 {
  font-weight: bold;
  color: #333;
  margin: 10px 0;
}

.services p {
  color: #333;
  line-height: 1.6;
  padding: 0 20px;
  margin-bottom: 20px;
}

.contact {
  display: flex;
  justify-content: space-between;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 40px 0;
  gap: 20px;
}

.contact p {
  color: #333;
  line-height: 1.6;
}

.contact img {
  width: 100%;
  height: auto;
}

.faq {
  text-align: center;
  font-weight: bold;
  margin: 20px 0;
}

.why-choose-agent-section, .section-container, .logistics-banner {
  margin: 40px 0;
}

.contact-text {
  border: 1px solid #ccc;
  padding: 30px 45px;
}

.nav-icon {
  max-width: 80%;
}

.package-container {
  text-align: left;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.title {
  font-weight: bold;
  color: #333;
  text-align: center;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin: 0;
  padding: 0;
}

.title h2 {
  margin: 0;
  font-size: 2.5rem;

}
.title span {
  flex: 1;
}

.chevrons {
  display: block;
  width: 100%;
}

.title img {
  width: 100%;
  height: auto;
}

.list-item {
  margin-bottom: 15px;
  color: #333;
  line-height: 1.6;
}

.list-item strong {
  margin-right: 10px;
}

.faq .faq-item {
  text-align: left;
}



@media screen and (min-width: 1500px) {
  .intro-content {
    padding: 0 !important;
  }

}


.background {
  background-size: cover;
  display: flex;
  justify-content: center;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  min-height: 470px;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
}

.namibia-agent {
  background-image: url('../../../public/images/localServices/namibiaagentrecent.png'); /* Replace with your image path */
  margin: 40px 0;
  background-position: center center;
  height: auto;
}


.logistics {
  background-image: url('../../../public/images/localServices/logisticstransparent.png'); /* Replace with your image path */
  justify-content: flex-start;
  
}


.services-text {
  line-height: 1.5;
  /* background-color: rgba(0, 0, 0, 0.5); */
  padding: 15px;
  text-align: left;
  position: relative;
  left: 20px;
  top: 60px;
}

.namibia-agent .services-text {
  max-width: 500px;
}

.sub-text {
  position: relative;
  top: 20%;
  right: 10%;
}


.logistics-agent-info {
  line-height: 1.6;
  padding: 20px;
  position: relative;
  top: 20px;
  left: 20px;
  border-radius: 10px;
  max-width: 600px; /* Limit the width of the text container */
  text-align: left;
}

.logistics-agent-info a {
  color: inherit;
}

.logistics-banner {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
/* Media query for screens wider than 1260px */
@media (min-width: 1400px) {
  .background {
    background-position: top center; /* Adjust the position as needed */
    min-height: 490px; /* Optional: Increase the height if needed */
  }

  .namibia-agent .services-text , .logistics-agent-info {
        max-width: 580px;
        left: 40px;
  }

  .logistics-agent-info {
    max-width: 700px;
  }

 .title h2 {
  font-size: clamp(1.75rem, 3.5vw, 2.95rem);
 }

 .title img {
  width: 85%;
 }


 
}

.contact-section-wrapper {
  margin: 40px 0;
}
.contact-section-container {
  position: relative;
 
}

.contact-section-container img {
  width: 100%; 
  height: auto;
}

.contact-text-container {
  display: flex;
  justify-content: space-around;
  position: absolute;
  top: 60%;
  width: 90%;
}

.contact-text-container p {
  display: inline-block;
  max-width: 25%;
}

.contact-cta-section  p{
  margin: 30px;
}

.show-faq-btn {
  color: #ffff;
  background-color: #e67e22;
  font-size: 25px;

}

.namibia-agent-container .faq-list h3 {
  margin-bottom: 20px;
  margin-top: 0;
}