/* Force desktop styles for smaller screens */

html {
  -webkit-text-size-adjust: 100%; /* Disable auto-scaling on Android */
  text-size-adjust: 100%;
  scroll-behavior: smooth;

}

p, ul li, ol li, .list-item, .services-text, .logistics-agent-info, .cta-link, 
.bank-info-table td, .footer-inner a, .paypay-link {
  font-size: clamp(1.1rem, 1.8vw, 1.2rem); /* Reduced */
}

@media  (min-width: 1400px) {
  p, ul li, ol li, .list-item, .services-text, .logistics-agent-info, .cta-link, 
  .bank-info-table td, .footer-inner a, .paypay-link {
    font-size: clamp(1.1rem, 1.5vw, 1.2rem); /* Reduced */
  }
}
.links a {
  font-size: clamp(0.9rem, 1vw, 1.1rem);
}

h1 {
  font-size: clamp(1.7rem, 1.8vw, 2rem); 
}

h2 {
  font-size: clamp(1.5rem, 1.6vw, 1.7rem); 
}

h3 {
  font-size: clamp(1.25rem, 1.4vw, 1.5rem); 
}

h4 {
  font-size: clamp(1.1rem, 1.3vw, 1.4rem); 
}

h5 {
  font-size: clamp(1rem, 1.2vw, 1.3rem); 
}

h6 {
  font-size: clamp(0.85rem, 1vw, 1.1rem); 
}

small, .top-bar span, .profile-sidebar-menus li {
  font-size: clamp(0.8rem, 0.9vw, 0.9rem); 
}

blockquote {
  font-size: clamp(1.05rem, 1.6vw, 1.2rem); 
}

caption {
  font-size: clamp(0.9rem, 1vw, 1.05rem); 
}

nav {
  font-size: clamp(0.9rem, 1vw, 1.1rem); 
}

a, .nav-item, i {
  font-size: clamp(0.9rem, 1vw, 0.95rem); 
}

td {
  font-size: clamp(0.9rem, 1vw, 1.1rem); 
}

th {
  font-size: clamp(1rem, 1.2vw, 1.2rem); 
}

label {
  font-size: clamp(0.9rem, 1vw, 1.1rem); 
}

/*
input {
  font-size: clamp(0.95rem, 1.2vw, 1.15rem); 
}
*/
/*
.logistics-agent-info, .services-text, .logistics-agent-info a {
  font-size: clamp(0.875rem, 1.8vw, 2rem) !important;
}
*/
@media (min-width: 1400px) {
  .intro-content p, .logistics-agent-info, .services-text, .logistics-agent-info a {
    font-size: clamp(1rem, 1.5vw, 1.5rem); 
  }


  .links a {
    font-size: clamp(1.125rem, 1vw, 1.50rem);
  }

  .congo-agent-container .intro-content p {
    font-size: clamp(1.4rem, 1.25vw, 1.25rem) ;
  }
}


select, input[type='date']{
  -webkit-appearance: none; /* Remove default styling on iOS */ 
  -moz-appearance: none;/* Remove default styling on Firefox */
}

body {
  overflow-x:hidden ;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.header-container, .used-tires-page{

  max-width: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100dvh;
}


@media screen and (max-width: 9999px) {
    .layout, .usedTiresForm-container, .car-details, .help-page , .footer-inner, .shipping-container , 
    .top-bar, .profile-wrapper ,.namibia-agent-container, .congo-agent-container, .japan-exports  {
        margin: 0 auto;
        width: 90%; 
        max-width: 1540px; 
    }
    .header-bottom, .header-top, .local-services-banner {
        margin: 0 auto;
        width: 90%;
        max-width: 1480px;
    }

    .top-bar {
        max-width: 1730px;
        width: 90%;
        margin: 0 auto;
    }

    .extra-info {
        margin: 0 -89px;
    }


}

@media screen and (max-width: 1760px) {
    .layout, .usedTiresForm-container, .car-details, .help-page , .footer-inner, .shipping-container ,
    .top-bar ,.profile-wrapper, .namibia-agent-container, .congo-agent-container , .japan-exports  {
        margin: 0 auto;
        width: 90%;
        max-width: 1438px; 
    }
    .header-top, .header-bottom {
        width: 90%; 
        max-width: 1460px; 
    }
}

@media screen and (max-width: 1530px) {
    .layout, .header-top, .local-services-banner, .usedTiresForm-container, .car-details, .help-page, 
    .footer-inner, .shipping-container ,.top-bar , .profile-wrapper , 
    .namibia-agent-container ,.congo-agent-container, .japan-exports  {
        margin: 0 auto;
        width: 90%; 
        max-width: 1330px; 
    }
    .header-bottom {
        width: 90%; 
        max-width: 1330px; 
    }
}

@media screen and (max-width: 1500px) {
  .layout, .header-top, .local-services-banner, .usedTiresForm-container, .car-details, .help-page, 
  .footer-inner, .shipping-container , .top-bar ,.profile-wrapper , .namibia-agent-container, 
  .congo-agent-container, .japan-exports {
      margin: 0 auto;
      width: 100%; 
      max-width: 100%;
  }
  .header-bottom {
      width: 90%;
      max-width: 1330px; 
  }



  
}

@media screen and (min-width:1500px) {
    /*
    .terms-container, .asf, .whyChoose, .dismantling, .overview, .envirement-policy, 
    .security-notice, .paypal, .howtopay, .aboutusedtires {
        margin: 0 !important;
      }
    */
      .sidebar {
        padding: 0 !important;
      }
    
}





/* 404 Page Styles */
.not-found-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  background-color: #f4f4f4;
  text-align: center;
  padding: 20px;
}

.not-found-heading {
  font-size: 3rem;
  font-weight: bold;
  color: #e74c3c;
  margin-bottom: 10px;
}

.not-found-description {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 20px;
}

.not-found-description a {
  color: #3498db;
  text-decoration: none;
  font-weight: bold;
}

.not-found-description a:hover {
  text-decoration: underline;
}
