/* Base styles */
/* Force desktop styles for smaller screens */

.max-w-4xl {
  padding: 1.5rem;
}

.text-3xl {
  font-size: 1.875rem;
  font-weight: bold;
  text-align: center;
  color: #d97706;
  margin-bottom: 1.5rem;
}

.text-xl {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.text-lg {
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.italic {
  font-style: italic;
}

.font-bold {
  font-weight: bold;
}

.grid {
  display: grid;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.md\:grid-cols-3 {
  grid-template-columns: 2fr 1fr 1fr; /* Adjusted to make the left testimonial take more space */
}

.gap-14 {
  gap: 8rem; /* Increased spacing to move right-side testimonials further right */
}

.md\:col-span-1 {
  grid-column: span 1 / span 1;
}

.md\:col-span-2 {
  grid-column: span 2 / span 2;
  margin-left: 5rem; /* Further shifted the right-side testimonials to the right */
}

.space-y-6 > * + * {
  margin-top: 1.5rem;
}

.border-yellow-600 {
  border-color: #d97706;
}

.md\:flex {
  display: flex;
}

.md\:items-start {
  align-items: flex-start;
}

.md\:w-2\/3 {
  width: 66.666667%;
}

.md\:w-1\/3 {
  width: 33.333333%;
}

.md\:pr-6 {
  padding-right: 1.5rem;
}

.w-full {
  width: 100%;
}

.h-auto {
  height: auto;
}
/* Container for sections that should align in one row and wrap on small screens */
.ab-consulting .section-container {
  display: flex;
  gap: 1.5rem; /* Adjust gap between columns as needed */
}

/* Left (text) section: takes about 2/3 of the space on large screens */
.section-text {
  flex: 2 1 300px; /* Grow factor of 2, shrink as needed, with a minimum basis of 300px */
  padding-right: 1.5rem;
  box-sizing: border-box;
}

/* Right (image) section: takes about 1/3 of the space on large screens */
.section-image {
  flex: 1 1 200px; /* Grow factor of 1, shrink as needed, with a minimum basis of 200px */
  box-sizing: border-box;
}

/* Ensure images scale nicely */
.section-image img {
  object-fit: cover;
}




/* part 2*/

.image-container {
  margin-bottom: 24px;
}
.image-container img {
  width: 100%;
  border-radius: 8px;
}

.font-bold {
  font-weight: bold;
}
.mt-4 {
  margin-top: 16px;
}
.mt-6 {
  margin-top: 24px;
}
.list-disc {
  list-style-type: disc;
  padding-left: 20px;
}
.list-inside {
  list-style-position: inside;
}

/* part 3 */

.expertise-content ol {
  list-style-type: decimal;
  padding-left: 20px;
}
.expertise-content ol li {
  font-weight: bold;
  margin-top: 16px;
}
.expertise-content p, .expertise-content ul {
  margin-left: 24px;
  margin-top: 4px;
}
.expertise-content ul {
  list-style-type: disc;
}
.divider {
  border-top: 4px solid #f59e0b;
  margin: 32px 0;
}
.flex-container {
  display: flex;
  gap: 32px;

}
.flex-container > div {
  flex: 1;
}
.why-artisbay, .our-network {
  margin-bottom: 32px;
}
.why-artisbay h2, .our-network h2 {
  font-weight: bold;
  margin-bottom: 16px;
}
.why-artisbay p, .why-artisbay ul {
  margin-bottom: 16px;
}
.why-artisbay ul {
  list-style-type: none;
  padding-left: 0;
}
.why-artisbay ul li {
  text-decoration: underline;
}
.network-icons {
  display: flex;
  justify-content: center;
  gap: 16px;
}

.network-icons img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

/* part 4 */

/* Custom styles for the consultation section */

.heading-primary {
  font-weight: bold;
  text-align: center;
  color: #f1892b; /* Equivalent to text-yellow-600 */
  margin-bottom: 1.5rem;
}

.heading-secondary {
  font-weight: bold;
  margin-bottom: 0.5rem; /* Equivalent to mb-2 */
  padding: 0 !important;
}

.content-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.5rem; /* Optional section spacing */
}

.text-content,
.image-content {
  margin: 0;
  padding: 0;
}

/* Remove default margins on headings and paragraphs inside */
.text-content h3,
.text-content p,
.image-content p {
  margin: 0;
  padding: 0;
}

.text-content {
  flex: 1;
}

.image-content {
  text-align: center;
  flex-shrink: 0;
}

.image-content img {
  display: block;
  max-width: 150px;
  height: auto;
  margin: 0;
}


.footer-section {
  border-top: 4px solid #f1892b; /* Equivalent to border-yellow-600 */
  padding-top: 1rem; /* pt-4 */
}

.footer-section p {
  margin-bottom: 1rem; /* mb-4 */
}

.cta button {
  background-color: #1da1f2; /* bg-blue-600 */
  color: #fff;
  padding: 0.5rem 1rem; /* px-4 py-2 */
  border: none;
  border-radius: 0.25rem; /* rounded */
  cursor: pointer;
}

.special-products-list li {
  font-weight: unset !important;
}

.language-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.language-switcher button {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.language-switcher button:hover {
  background-color: #1da1f2;
  color: #fff;
  border-color: #1da1f2;
}

.language-switcher button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}

/* Active button styles */
.language-switcher button.active {
  background-color: var(--accent-color);
  color: #fff;
  border-color: #f89800;
}