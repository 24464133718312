.help-page {
  display: flex;
  flex-direction: column;
}

.hidden {
  display: none;
}

.hero-section-help {
  position: relative;
  width: 100%;
  height: 50dvh; /* Adjust the height as needed */
  max-height: 200px;
  color: white;
  padding: 40px;
  text-align: center;
  background-image: url('../../../public/images/Help.jpeg');
  background-size: cover; /* Ensure the image covers the full width */
  background-position: center center; /* Center the image both horizontally and vertically */
  background-repeat: no-repeat;
}

.help-lp {
  background-image: url('../../../public/images/custumersupportcopy.JPEG');
  background-size: cover;
}

.commitment-topic-lp {
  background-image: url('../../../public/images/eco1copy.jpeg');
  background-size: cover;
  background-position: center;
}

.hero {
  position: relative;
  margin-bottom: -4px;
}

.hero h1 {
  position: absolute;
  top: 50%;
  left: 6%;
  transform: translateY(-50%);
  color: #1da1f2;
  padding: 10px;
  text-shadow: 1px 1px 1px #ffffff; /* Added text shadow */
}

.help-header {
  color: #1da1f2;
  justify-self: center;
  text-align: center;
  text-shadow: 1px 1px 1px #333; /* Added text shadow */
  text-transform: capitalize;
  cursor: pointer;
  margin-bottom: 10px;
}

.help-header.oriented {
  text-orientation: upright;
  writing-mode: vertical-rl;
  margin: 0;
}

.help-main-content {
  display: flex;
  flex: 1;
  justify-content: center;
  gap: 20px; /* Adds space between sidebar and content */
}

.topic-image {
  width: 100%; /* Makes the image responsive */
  height: auto; /* Maintains the aspect ratio */
  max-height: 400px; /* Sets a maximum height for the image */
  object-fit: cover;
}

.sidebar {
  max-height: 100dvh; /* Makes sidebar scrollable if needed */
  overflow-y: auto; /* Enables scrolling */
  padding: 0 20px; /* Original padding */
  position: sticky;
  top: 20px; /* Sticks to the top with some spacing */
  background-color: rgba(255, 255, 255, 0.5); /* Original background color */
  width: 200px;
  transition: width 0.3s ease;
}


.sidebar.collapsed {
  width: 65px; /* Collapsed width */
  overflow-y: unset;
}

/* Transition for the help header rotation */
.help-header, .arrow-icon.vertical, .arrow-icon-text.vertical {
  transition: transform 0.3s ease;
}

.help-header.vertical , .arrow-icon.vertical, .arrow-icon-text.vertical {
  transform: rotate(-90deg);
  /*transform-origin: right top;*/
  margin: -15px;
}

.sidebar-header.vertical {
  transform: rotate(-90deg);
}



.arrow-icon-container {
  display: flex;
  align-items: center;
  padding: 5px 10px;
}

.arrow-icon-text.vertical {
  margin: -10px;
  
}
.arrow-icon-text {
  display: block;
  font-weight: bold;
  cursor: pointer;
}

.arrow-icon {
  cursor: pointer;
  align-self: flex-end;
}
/* Ensure the sidebar header is vertically centered when collapsed */
.sidebar.collapsed .sidebar-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  
}

.sidebar h1 {
  text-align: center; /* Center the header */
  text-shadow: 1px 1px 5px #ddd; /* Original text shadow */
}

.sidebar button {
  display: block;
  width: 100%;
  padding: 10px; /* Original padding */
  text-align: left; /* Original alignment */
  margin: 10px 0; /* Original margin */
  text-transform: capitalize; /* Original text transform */
  border: none; /* Original border */
  color: #333; /* Original color */
  font-weight: bold; /* Original font weight */
  background-color: rgba(255, 255, 255, 0.5); /* Original background color */
  text-shadow: 1px 1px 5px #ddd; /* Original text shadow */
  cursor: pointer; /* Adds pointer cursor for better UX */
  transition: background-color 0.3s ease; /* Smooth hover effect */

}

.sidebar button:hover {
  background-color: rgba(241, 137, 43, 0.8); /* Hover effect using original active color */
  color: white; /* Text color on hover */
}

.sidebar button.active {
  background-color: #f1892b; /* Original active color */
  color: white; /* Original active text color */
  text-shadow: none; /* Original active text shadow */
}

.btn-header {
  background-color: unset !important; /* Original background */
  text-align: center !important; /* Original alignment */
  margin: 0 !important; /* Original margin */
}

.content-area {
  padding: 20px 0; /* Original padding */
  flex: 1;
  overflow-y: auto;
  
}

.content-header {
  text-decoration: underline; /* Original underline */
  text-transform: capitalize; /* Original text transform */
}

.content-area h2 {
  margin-top: 0; /* Original margin */
  text-transform: capitalize; /* Original text transform */
}

.sidebar.collapsed {
  border: none;
  background-color: unset;
}