.top-bar-wrapper {
  background-color: #1da1f2;
}

.stock {
  visibility: hidden;
}
.top-bar {

    padding: 5px 10px;  /* Adjust padding for a better layout */
    display: flex;
    justify-content: space-around;
    align-items: center;
  /* Smaller font size */
    font-weight: bold;
    color: #fff;
    font-size: 12px;
  }
  
  .app-info .app-name {
    font-weight: bold;
    color: #fff;
  /* Reduced font size */
    margin-right: 20px;  /* Space between app name and other elements */
  }
  
  .extra-info {
    display: flex;
    align-items: center;  /* Align items vertically centered */
    gap: 15px;  /* Adjust gap for a tighter layout */
  }
  
  .extra-info .time,
  .extra-info .exchange-rate {
    color: #fff;
  /* Smaller font size */
  }
  
  .extra-info select {
    margin-left: 10px; /* Space between text and select boxes */
    padding: 5px; /* Padding for select boxes */
    border-radius: 4px; /* Rounded corners for select boxes */
    border: 1px solid #ccc; /* Border style */
 /* Match font size */
  }
  
.app-info span {
    margin: 20px;
}

.exchange-rate input {
  width: 20%;
  font-size: inherit;
}