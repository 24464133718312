.formContainer {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: auto;
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .heading {
    width: 100%;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  /* Layout columns side by side */
  .columns {
    display: flex;
    gap: 2rem;
    width: 100%;
  }
  
  /* Each column takes equal available space */
  .column {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  /* Each row has a label and an input on the same line */
  .formRow {
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .formRow span {
    width: 140px; /* fixed width for consistent alignment */
    font-weight: bold;
    color: #333;
  }

  .formLabel {
    width: 160px;  /* adjust width as needed */
    font-weight: bold;
    color: #333;
  }

  
  /* Inputs, textareas, etc. */
  .formInput {
    flex: 1;
    padding: 0.5rem;
    font-size: 0.9rem;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .formTextarea {
    flex: 1;
    padding: 0.5rem;
    font-size: 0.9rem;
    border-radius: 4px;
    border: 1px solid #ccc;
    min-height: 80px;
    resize: vertical;
  }
  
  /* Checkbox row is separate from label/input pairs */
  .checkboxRow {
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
  }
  
  .checkboxLabel {
    display: inline-flex;
    align-items: center;
    gap: 0.25rem;
    font-weight: normal;
  }
  
  .checkbox {
    width: 16px;
    height: 16px;
  }
  
  /* Buttons */
  .buttonContainer {
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }
  
  .buttonContainer .button {
    padding: 0.75rem 1.5rem;
    margin-right: 1rem;
    border: none;
    border-radius: 4px;
    background: #007bff;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .cancelButton {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    background: #ccc;
    color: #333;
    font-size: 1rem;
    cursor: pointer;
  }
  
  .alert-modal-content.add-customer {
    width: 99%;
    overflow-y: auto;
    max-width: unset;
    background-color: #f3f3f3;
  }


  @media screen and  (min-width: 900px){
    .alert-modal-content.add-customer {
        height: 100dvh;
    }
  }