

/* Banner Section */
.banner {
  width: 100%;
  overflow: hidden;
  text-align: center;
}

.banner-image {
  width: 100%;
  height: auto;
  display: block;
}

/* Introduction Section */
.introduction-section {
  margin-bottom: 60px;
  text-align: center;
  background: #fff;
  padding: 40px 20px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.introduction-section h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #1e3a8a;
}

.introduction-section p {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
}

/* Services Section */
  .service {
      display: flex;
      align-items: flex-start;
      margin-bottom: 40px;
  }
  .service i {
      font-size: 24px;
      color: #1e3a8a;
      margin-right: 10px;
  }
  .service h2 {
      font-size: 20px;
      color: #000;
      margin: 0 0 10px 0;
  }
  .service p {
      font-size: 16px;
      color: #333;
      margin: 0;
  }

/*
@media (min-width: 768px) {
.congo-agent-container .services {
display: flex;
flex-wrap: wrap;
justify-content: center;
}
.congo-agent-container .service {
width: 48%;
}
}
*/
/* Automated Invoice System Section */
.invoice-section {
  background: #fff;


}

.invoice-section-content {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 40px 20px;
  border-radius: 12px;

  margin-bottom: 60px;
}

.invoice-section-content a {
  text-align: center;
}

.invoice-section h1 {
  margin-bottom: 20px;
  text-align: center;
}

.invoice-section p {
  line-height: 1.8;
  margin-bottom: 20px;
}

.invoice-section ul {
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  line-height: 1.6;
}

.invoice-section ul li {
  margin-bottom: 10px;
}

.invoice-link {
  display: inline-block;
  background: #1e3a8a;
  color: #fff;
  padding: 12px 24px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 600;
  transition: background 0.3s ease;
}

.invoice-link:hover {
  background: #1a2f6b;
}

/* Contact Section */
.congo-agent-container .contact-section {
  text-align: center;
  margin-bottom: 60px;
}

.congo-agent-container .contact-section {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.contact-section h1 {
  margin-bottom: 20px;
  color: #1e3a8a;
}

.contact-section p {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.8;
  max-width: 800px;
  margin: 0 auto;
}

.contact-section strong {
  color: #1e3a8a;
}

.congo-agent {
  background: url('../../../public/images/localServices/congoagentrecent.png');
}

.congo-agent .services-text {
  left:60px;
  max-width: 600px;
}

.congo-agent .sub-text {
  right: 0;
}

